import React from "react";

import { Link } from 'gatsby';

import classNames from "classnames";

import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import featureStyle from "assets/jss/material-kit-react/views/landingPageSections/featureStyle.jsx";



class ExportHere extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <GridContainer className={classes.featureGrey}>
          <GridItem xs={12} lg={3}>
              <div className={classes.featureRightInfo}>

                <h2 className={classes.title}>Generate Here</h2>


                <h4 className={classes.description}>
                  Generate platform optimized eBooks from your book or boxset.
                </h4>
                <h4 className={classes.description}>
                   Platform specific store links supported.
                </h4>
                <h4 className={classes.description}>
                  Support for all major platforms.
                </h4>
                <h4 className={classes.description}>
                   Print support coming very soon.
                </h4>

                </div>
            </GridItem>
          <GridItem xs={12} lg={9}className={classes.exampleGrid}>
            <div className={classes.exampleImageLeft}>
                  <img
                      src={require('pages/HomePage/img/bookMerlinBeach2.jpg')}
                       className={classes.featureLeftImg}/>
            </div>
          </GridItem>

        </GridContainer>
      </div>
    );
  }
}

export default withStyles(featureStyle)(ExportHere);
